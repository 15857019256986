.heading-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 0 50px 0;
}
.screen-sub-heading{
    letter-spacing: 3px;
    color: white;
    font-size: 1rem;
    margin: 4px 0px 8px 0px;
}
.screen-heading{
    color: white;
    font-size: 2.4rem;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}
.heading-seperator{
    display: flex;
    align-items: center;
    position: relative;
    margin: 5px 0 0 0;
    width: 180px;
}
.seperator-line{
    width: 100%;
    height: 2px;
    background-color: rgb(3, 47, 54);
}
.seperator-blob{
    height: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: -4px;
}
.seperator-blob div{
    width: 35px;
    border-radius: 10px;
    background-color: orangered;
}
@media screen and (max-width:420px) {
    .screen-heading{
        font-size: 19px;
    }
    .screen-sub-heading{
        font-size: 16px;
    }
}