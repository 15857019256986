.about-me-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 80px 0 0 0;
    background-color: black;
    padding-left: 100px;
    padding-right: 100px;
}
.about-me-parent{
    width: 100%;
    margin-bottom: 50px;
}
.about-me-card{
    display: flex;
    width: 100%;
    box-shadow: 0 0px 20px -2px #1f2235;
}

.about-image img{
    width: 300px;
    border-radius: 10px;
    border: 8px solid orangered;
}
.about-me-profile{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.about-me-details{
    width: 48%;
    text-align: justify;
    padding: 30px;
}
.about-me-description{
    font-family: 'Poppins', sans-serif;
    font-size: .9rem;
    color: white;
    letter-spacing: 1.4px;
    font-weight: 300;
}
.about-me-hightlights{
    margin: 50px 0;
}
.highlight-heading{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    margin: 0 0 5px 0;
    color: white;
    font-weight: 500;
}
.Highlights{
    display: flex;
    align-items: center;
    margin: 5px 0;
    font-family: 'Poppins', sans-serif;
    font-size: .9rem;
    font-weight: 300;
    color: white;
}
.Highlights-blob{
    height: 12px;
    width: 12px;
    margin: 0 10px 0 0;
    background-color: orangered;
    border-radius: 50%;
}
.about-me-options button{
    background: black;
    border: 1px solid white;
}
.about-me-options .download-link{
    margin: 0 0 0 30px;
    padding: .5rem 1.2rem;
    background-color: rgb(230, 72, 15);
    border-radius: 30px;
    margin-left: 15px;
    text-decoration: none;
    color: rgb(0, 0, 0);
    font-family: 'Poppins', sans-serif;
    letter-spacing: 2px;
    cursor: pointer;
    border: none;
}
.download-link a{
    text-decoration: none;
    color: black;
}
.download-link:hover{
    background-color: rgb(250, 200, 108);
    transition: 1s ease-out;
}
@media only screen and (max-width:110px){
    .about-me-parent{
        width: 88%;
    }
}
@media only screen and (max-width:850px){
    .about-me-profile{
        display: none;
    }
    .about-me-details{
        width: 100%;
    }
}
@media only screen and (max-width:440px){
    .about-me-container{
        padding-left: 30px;
        padding-right: 30px;
    }
    .about-me-options{
        display: flex;
        flex-direction: column;
        margin-top: -30px;
    }
    .about-me-options button{
        width: 100%;
    }
    .about-me-details{
        width: 100%;
    }
    .about-me-options .download-link{
        background: orangered;
        width: 100%;
        margin: 20px 0 0 !important;
    }
    .about-me-description{
        font-size: .8rem;
        letter-spacing: 1.6px;
    }
    .Highlights{
        font-size: .8rem;
    }
    .Highlights-blob{
        height: 10px;
        width: 10px;
        
    }
}