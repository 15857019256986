.resume{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}
.resume img{
    margin-top: 30px;
    margin-bottom: 30px;
    width: 500px;
}

@media screen and (max-width:600px){
    .resume img{
        width: 300px;
    }
}