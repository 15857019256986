.main-container{
  margin-top: 100px;
}
.back-form{
  display: flex;
  height: 70vh;
  margin-left: 100px;
  margin-right: 100px;
}
.title{
  margin-left: 100px;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 300;
}
.img-back{
  width: 47%;
}
.img-back img{
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.back-form form{
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  box-shadow: 0 0 10px rgb(190, 188, 188);
  padding: 20px;
  height: 90%;
}
.back-form form input,textarea{
  width: 100%;
  height: 30px;
  border: none;
  outline: none;
  border-radius: 5px;
  padding-left: 5px;
  margin-top: 5px;
}
.back-form form label{
  color: white;
  margin-top: 5px;
}
.back-form form textarea{
  height: 60px;
}
.back-form form button{
  background-color: rgb(182, 48, 0);
  border: none;
  outline: none;
  padding: 5px 25px;
  margin-top: 10px;
  border-radius: 20px;
  color: white;
}

@media screen and (max-width:810px){
  .back-form{
    flex-direction: column;
  }
  .img-back{
    width: 100%;
  }
  .back-form form{
    width: 100%;
    margin-left: 0px;
    height: 400px;
  }
  .img-back img{
    height: 150px;
  }
  .back-form form button{
    margin-top: 30px;
  }
}
@media screen and (max-width:420px){
  .back-form{
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
  }
  .title{
    margin-left: 20px;
  }
  .back-form form{
    height: 330px;
    
  }
}
@media screen and (max-width:380px){
  .back-form{
   height: 85vh;
   margin-bottom: 30px;
  }
}