.btn-1{
  color: black;
  padding: .4rem 1.5rem;
  border: none;
  border-radius: 30px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 2px;
  cursor: pointer;
  border: 1.5px solid orangered;
  background: none;
  color: white;
}
.btn-1:hover{
  background-color: rgb(224, 76, 22);
  transition: 1s ease-out;

}

@media screen and (max-width:420px){
  .btn-1{
    padding: .6rem 1rem;
    font-size: .8rem;
  }
}
@media screen and (max-width:380px){
  .btn-1{
    padding: .4rem 1rem;
    font-size: .8rem;
  }
}