.project-section{
  margin-top: 100px;
}
.project-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.project{
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 13px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(242, 242, 245, 0.308);
  margin-bottom: 50px;
}
.project img{
  width: 100%;
  height: 150px;
  object-fit: cover;
}
.project p{
  text-align: justify;
  margin-top: 30px;
  color: white;
}
.project a{
  background-color: rgb(204, 54, 0);
  padding: 5px 10px;
  color: white;
  text-decoration: none;
}