.hero{
    display: flex;
    align-items: center;
    text-align: center;
    height: 600px;
    background-color: black;
    color: white;
}
.hero-wrapper{
    display: flex;
    text-align: start;
    justify-content: space-between;
    align-items: center;
    padding-left: 100px;
}
.hero-social-icon{
    font-size: 23px;
}

.hero-social-icon i:not(:first-child){
    padding-left: 15px;
}
.hero-social-icon i:hover{
    color: rgb(240, 200, 126);
    cursor: pointer;
}
.hero-left-name{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    letter-spacing: 3px;
    padding-top: 10px;
    font-size: 34px;
}
.hero-left-name span{
    color: #c43400;
}
.hero-left-name-slide{
    font-family: 'Poppins', sans-serif;
    letter-spacing: 3px;
    font-weight: 500;
    font-size: 27px;
}
.hero-left-para{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 300;
    width: 90%;

}
.hero-btns{
    margin-top: 10px;
}
.hero-left-download{
    padding: .5rem 1.2rem;
    background-color: #c43400;
    border-radius: 30px;
    margin-left: 15px;
    text-decoration: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 2px;
    cursor: pointer;
}
.hero-left-download:hover{
    background-color: rgb(250, 200, 108);
    transition: 1s ease-out;
}
.hero-right{
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 0px white;
    border-radius: 50%;
    height: 310px;
    width: 300px;
    margin: 0 0px 0 260px;
}
.hero-right .hero-image{
    width: 90%;
    height: 92%;
    background-size: cover;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
}
.hero-right .hero-image:hover{
    transform: scale(1.07);
    transition: 1s ease-out;
}

@media screen and (max-width:780px){
    .hero{
        justify-content: center;
        height: 950px;
    }
    .hero-wrapper{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-left: 50px;
        
    }
    .hero-right{
        margin: 0 0px 50px 0px;
    }
}
@media screen and (max-width:550px){
    .hero{
        height: 100%;
    }
    .hero-wrapper{
        padding-left: 0px;
        padding-top: 60px;
    }
    .hero-right{
        width: 250px;
        height: 270px;
    }
    .hero-left-para{
        text-align: center;
        width: 100%;
    }
}
@media screen and (max-width:420px){
    .hero-left-name{
        font-size: 20px;
    }
    .hero-left-name-slide{
        font-size: 20px;
    }
    .hero-left-para{
        font-size: 15px;
        padding-left: 20px;
        padding-right: 20px;
    }
}