.footer{
    background-color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 100px 20px 100px;
}
.footer h4{
    font-size: 15px;
    color: white;
    font-family: 'Poppins', sans-serif;
}
.footer-icon a{
    text-decoration: none;
    color: white;
    padding-left: 45px;
}
@media screen and (max-width:780px){
    .footer{
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
    }
    .footer h4{
        font-size: 17px;
        
    }
    .footer-icon{
        margin-left: -40px;
    }
    .footer-icon a{
        padding-left: 35px;
    }
}
@media screen and (max-width:550px){
    .footer{
        padding-bottom: 0px;
    }
}
@media screen and (max-width:420px){
    .footer{
        width: 100%;
        justify-content: start;
    }
    .footer h4{
        font-size: 15px;
        width: 300px;
        
    }
}